import { CRMCardInsertInput, CRMCardQueryInput } from '../../crm/models/CRMCard';
import MasterDataCountry, {
  MasterDataCountryInsertInput,
  MasterDataCountryQueryInput,
} from '../../countries/models/MasterDataCountry';
import MasterDataLanguage, {
  MasterDataLanguageInsertInput,
  MasterDataLanguageQueryInput,
} from '../../languages/models/MasterDataLanguage';
import MasterDataEventType, {
  MasterDataEventTypeInsertInput,
  MasterDataEventTypeQueryInput,
} from './MasterDataEventType';
import BaseModel from '../../../models/BaseModel';
import BaseQueryInputModel from '../../../models/BaseQueryInputModel';
import Widget from '../../widgets/interfaces/Widget';
import BaseLogModel from '../../../models/BaseLogModel';
import BaseQueryInputLogModel from '../../../models/BaseQueryInputLogModel';

export enum MasterEventsView {
  Table = 'table',
  Grid = 'grid',
}

export enum MasterEventUserType {
  ADMIN = 'admin',
  IPAD = 'ipad',
}

export interface MasterEventUser {
  displayName: string;
  type: MasterEventUserType;
  externalUserId: string;
  insertedManually?: boolean;
}

export interface MasterEventCoverImage {
  unsplashId?: string;
  unsplashLink?: string;
  unsplashUserName?: string;
  unsplashUserUrl?: string;
  urlFull: string;
  urlRegular: string;
}

export interface MasterEventGuestsStats {
  total: number;
  totalRsvped: number;
  totalCheckedIn: number;
  totalCheckedOut?: number;
}

interface MasterEventGuestsStatsQueryInput {
  total?: number;
  total_exists?: number;
  total_gt?: number;
  total_gte?: number;
  total_in?: number;
  total_lt?: number;
  total_lte?: number;
  total_ne?: number;
  total_nin?: number;
  totalRsvped?: number;
  totalRsvped_exists?: number;
  totalRsvped_gt?: number;
  totalRsvped_gte?: number;
  totalRsvped_in?: number;
  totalRsvped_lt?: number;
  totalRsvped_lte?: number;
  totalRsvped_ne?: number;
  totalRsvped_nin?: number;
  totalCheckedIn?: number;
  totalCheckedIn_exists?: number;
  totalCheckedIn_gt?: number;
  totalCheckedIn_gte?: number;
  totalCheckedIn_in?: number;
  totalCheckedIn_lt?: number;
  totalCheckedIn_lte?: number;
  totalCheckedIn_ne?: number;
  totalCheckedIn_nin?: number;
  totalCheckedOut?: number;
  totalCheckedOut_exists?: number;
  totalCheckedOut_gt?: number;
  totalCheckedOut_gte?: number;
  totalCheckedOut_in?: number;
  totalCheckedOut_lt?: number;
  totalCheckedOut_lte?: number;
  totalCheckedOut_ne?: number;
  totalCheckedOut_nin?: number;
}

export default interface MasterEvent extends BaseModel, BaseLogModel {
  campaignId?: string;
  country?: MasterDataCountry;
  startAt: Date;
  endAt: Date;
  hasVirtualKeyboard?: boolean;
  language?: MasterDataLanguage;
  name: string;
  card?: string;
  secondCard?: string;
  type?: MasterDataEventType;
  userLanguage?: MasterDataLanguage;
  users: MasterEventUser[];
  ipads: string[];
  coverImage?: MasterEventCoverImage;
  widgets?: Widget[];
  isArchived?: boolean;
}

// MongoDB Realm GraphQL resolvers

export interface MasterEventUserInsertInput extends MasterEventUser {}

interface MasterEventUserQueryInput {
  AND?: MasterEventUserQueryInput[];
  OR?: MasterEventUserQueryInput[];
  displayName?: string;
  displayName_exists?: boolean;
  displayName_gt?: string;
  displayName_gte?: string;
  displayName_in?: string[];
  displayName_lt?: string;
  displayName_lte?: string;
  displayName_ne?: string;
  displayName_nin?: string[];
  guests?: MasterEventGuestsStatsQueryInput;
  type?: string;
  type_exists?: boolean;
  type_gt?: string;
  type_gte?: string;
  type_in?: string[];
  type_lt?: string;
  type_lte?: string;
  type_ne?: string;
  type_nin?: string[];
  externalUserId?: string;
  externalUserId_exists?: boolean;
  externalUserId_gt?: string;
  externalUserId_gte?: string;
  externalUserId_in?: string[];
  externalUserId_lt?: string;
  externalUserId_lte?: string;
  externalUserId_ne?: string;
  externalUserId_nin?: string[];
}

interface MasterEventCoverImageQueryInput {
  AND?: MasterEventCoverImageQueryInput[];
  OR?: MasterEventCoverImageQueryInput[];
  unsplashId: string;
  unsplashId_exists: boolean;
  unsplashId_gt: string;
  unsplashId_gte: string;
  unsplashId_in: string[];
  unsplashId_lt: string;
  unsplashId_lte: string;
  unsplashId_ne: string;
  unsplashId_nin: string[];
  unsplashLink: string;
  unsplashLink_exists: boolean;
  unsplashLink_gt: string;
  unsplashLink_gte: string;
  unsplashLink_in: string[];
  unsplashLink_lt: string;
  unsplashLink_lte: string;
  unsplashLink_ne: string;
  unsplashLink_nin: string[];
  unsplashUserName: string;
  unsplashUserName_exists: boolean;
  unsplashUserName_gt: string;
  unsplashUserName_gte: string;
  unsplashUserName_in: string[];
  unsplashUserName_lt: string;
  unsplashUserName_lte: string;
  unsplashUserName_ne: string;
  unsplashUserName_nin: string[];
  unsplashUserUrl: string;
  unsplashUserUrl_exists: boolean;
  unsplashUserUrl_gt: string;
  unsplashUserUrl_gte: string;
  unsplashUserUrl_in: string[];
  unsplashUserUrl_lt: string;
  unsplashUserUrl_lte: string;
  unsplashUserUrl_ne: string;
  unsplashUserUrl_nin: string[];
  urlFull: string;
  urlFull_exists: boolean;
  urlFull_gt: string;
  urlFull_gte: string;
  urlFull_in: string[];
  urlFull_lt: string;
  urlFull_lte: string;
  urlFull_ne: string;
  urlFull_nin: string[];
  urlRegular: string;
  urlRegular_exists: boolean;
  urlRegular_gt: string;
  urlRegular_gte: string;
  urlRegular_in: string[];
  urlRegular_lt: string;
  urlRegular_lte: string;
  urlRegular_ne: string;
  urlRegular_nin: string[];
}

export interface MasterEventUpdateInput
  extends Partial<Omit<MasterEvent, 'card' | 'secondCard' | 'country' | 'type' | 'language' | 'userLanguage'>> {
  card?: {
    create?: CRMCardInsertInput;
    link?: string;
  };
  secondCard?: {
    create?: CRMCardInsertInput;
    link?: string;
  };
  country?: {
    create?: MasterDataCountryInsertInput;
    link?: string;
  };
  type?: {
    create?: MasterDataEventTypeInsertInput;
    link?: string;
  };
  language?: {
    create?: MasterDataLanguageInsertInput;
    link?: string;
  };
  userLanguage?: {
    create?: MasterDataLanguageInsertInput;
    link?: string;
  };
}

export interface MasterEventInsertInput
  extends Omit<MasterEvent, 'country' | 'language' | 'userLanguage' | 'type' | 'users'> {
  country?: {
    create?: MasterDataCountryInsertInput;
    link?: string;
  };
  language?: {
    create?: MasterDataLanguageInsertInput;
    link?: string;
  };
  userLanguage?: {
    create?: MasterDataLanguageInsertInput;
    link?: string;
  };
  type?: {
    create?: MasterDataEventTypeInsertInput;
    link?: string;
  };
  users?: MasterEventUserInsertInput[];
}

export interface MasterEventQueryInput extends BaseQueryInputModel, BaseQueryInputLogModel {
  AND?: MasterEventQueryInput[];
  OR?: MasterEventQueryInput[];
  campaignId?: string;
  campaignId_exists?: boolean;
  campaignId_gt?: string;
  campaignId_gte?: string;
  campaignId_in?: string[];
  campaignId_lt?: string;
  campaignId_lte?: string;
  campaignId_ne?: string;
  campaignId_nin?: string[];
  card?: CRMCardQueryInput;
  card_exists?: boolean;
  country?: MasterDataCountryQueryInput;
  country_exists?: boolean;
  coverImage?: MasterEventCoverImageQueryInput;
  endAt?: Date;
  endAt_exists?: boolean;
  endAt_gt?: Date;
  endAt_gte?: Date;
  endAt_in?: Date[];
  endAt_lt?: Date;
  endAt_lte?: Date;
  endAt_ne?: Date;
  endAt_nin?: Date[];
  hasVirtualKeyboard?: boolean;
  hasVirtualKeyboard_exists?: boolean;
  hasVirtualKeyboard_ne?: boolean;
  language?: MasterDataLanguageQueryInput;
  language_exists?: boolean;
  name?: string;
  name_exists?: boolean;
  name_gt?: string;
  name_gte?: string;
  name_in?: string[];
  name_lt?: string;
  name_lte?: string;
  name_ne?: string;
  name_nin?: string[];
  secondCard?: CRMCardQueryInput;
  secondCard_exists?: boolean;
  startAt?: Date;
  startAt_exists?: boolean;
  startAt_gt?: Date;
  startAt_gte?: Date;
  startAt_in?: Date[];
  startAt_lt?: Date;
  startAt_lte?: Date;
  startAt_ne?: Date;
  startAt_nin?: Date[];
  type?: MasterDataEventTypeQueryInput;
  type_exists?: boolean;
  userLanguage?: MasterDataLanguageQueryInput;
  userLanguage_exists?: boolean;
  users?: MasterEventUserQueryInput[];
  users_exists?: boolean;
  users_in?: MasterEventUserQueryInput[];
  users_nin?: MasterEventUserQueryInput[];
}
