import { createContext, useState } from 'react';
import PageContainer from '../../../template/components/PageContainer/PageContainer';
import useSetBreadcrumbItems from '../../../template/components/Breadcrumb/useSetBreadcrumbItems';
import EventReportsFilters from '../components/EventReportsFilters/EventReportsFilters';
import { StyledContent } from '../../../template/components/Content/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ReportingCommands from '../components/ReportingCommands/ReportingCommands';
import EventReportsSelectionTable from '../components/EventReportsSelectionTable/EventReportsSelectionTable';

const pageTitle = 'Event reporting';

export enum ReportsFilters {
  MASTER_EVENTS = 'masterEvents',
  COUNTRIES = 'countries',
  SUB_EVENTS = 'subEvents',
  DATE_RANGE = 'dateRange',
  EVENT_STATUS = 'eventStatus',
}

export type IReportsFilter = {
  id: ReportsFilters;
  value: any;
};

const defaultReportsFilters: IReportsFilter[] = [
  { id: ReportsFilters.MASTER_EVENTS, value: [] },
  { id: ReportsFilters.SUB_EVENTS, value: [] },
  { id: ReportsFilters.COUNTRIES, value: [] },
  { id: ReportsFilters.DATE_RANGE, value: null },
  { id: ReportsFilters.EVENT_STATUS, value: ['all'] },
];

interface EventReportingContextInterface {
  filters: IReportsFilter[];
  setFilters: (filters: IReportsFilter[]) => void;
}

export const EventReportingContext = createContext<EventReportingContextInterface>({
  filters: [],
  setFilters: () => {},
});

function EventReporting() {
  useSetBreadcrumbItems([{ label: pageTitle }]);

  const [filters, setFilters] = useState<IReportsFilter[]>(defaultReportsFilters);

  return (
    <EventReportingContext.Provider value={{ filters, setFilters }}>
      <PageContainer pageTitle={pageTitle}>
        <StyledContent>
          <Box>
            <Typography variant='displayTitle'>{pageTitle}</Typography>
            <Typography variant='subheadline'>Generate reports of master events</Typography>
          </Box>
          <ReportingCommands />
        </StyledContent>
        <StyledContent>
          <EventReportsFilters />
        </StyledContent>
        <EventReportsSelectionTable />
      </PageContainer>
    </EventReportingContext.Provider>
  );
}

export default EventReporting;
