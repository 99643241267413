import GuestsFilter, { Item } from '../../../guests/components/GuestsFilter/GuestsFilter';

interface Props {
  onChange: (values: string[]) => void;
}

function EventReportsFiltersEventStatus(props: Props) {
  const { onChange } = props;

  const items: Item[] = [
    {
      _id: 'past',
      label: 'Past Events',
    },
    {
      _id: 'archived',
      label: 'Archived Events',
    },
  ];

  return (
    <GuestsFilter
      id='event-status-filter'
      label='Event Status'
      items={items}
      onChange={onChange}
      enableSelectAll={true}
      multiple={true}
    />
  );
}

export default EventReportsFiltersEventStatus;
