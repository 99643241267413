import { useLazyQuery } from '@apollo/client';
import { GET_MASTER_EVENTS } from './query';
import MasterEvent from '../../models/MasterEvent';
import Pagination, { defaultPagination } from '../../../../models/Pagination';
import BaseQueryInputModel from '../../../../models/BaseQueryInputModel';

export interface FindMasterEventsInput extends BaseQueryInputModel {
  limit: number;
  offset: number;
  sortBy: string;
  sortDirection: string;
  isOngoing?: boolean;
  isUpcoming?: boolean;
  isPast?: boolean;
  isArchived?: boolean;
  typeIn?: string[];
  typeNotIn?: string[];
  searchText?: string;
  countries?: string[];
  startDate?: Date;
  endDate?: Date;
}

interface QueryData {
  result: {
    data: MasterEvent[];
    pagination: Pagination;
  };
}

interface QueryVars {
  input: FindMasterEventsInput;
}

export interface PaginatedMasterEvents {
  masterEvents: MasterEvent[];
  pagination: Pagination;
}

/**
 * Fetch paginated list of master events
 */
function useFetchMasterEvents() {
  const [execute, { loading, error, data, refetch, called, client }] = useLazyQuery<QueryData, QueryVars>(
    GET_MASTER_EVENTS,
    {
      notifyOnNetworkStatusChange: true,
    },
  );

  const fetchMasterEvents = async (params: FindMasterEventsInput): Promise<PaginatedMasterEvents> => {
    const query: QueryVars = {
      input: {
        _partition: 'MASTER',
        ...params,
      },
    };

    if (!called) {
      // TODO GMB see if it's necessary to evict the cache (instead of returned the updated fields from the query)
      await client.refetchQueries({
        updateCache(cache) {
          cache.evict({ fieldName: 'findMasterEventsQuery' });
        },
      });
    }

    const response = called
      ? await refetch(query)
      : await execute({
          variables: query,
        });

    return {
      masterEvents: response.data?.result.data ?? [],
      pagination: response.data?.result.pagination ?? defaultPagination,
    };
  };

  return {
    fetchMasterEvents,
    loading,
    error,
    masterEvents: data?.result.data ?? [],
    pagination: data?.result.pagination ?? defaultPagination,
  };
}

export default useFetchMasterEvents;
