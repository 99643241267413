import React, { createContext, Fragment, lazy, Suspense, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { URI_MASTER_EVENTS } from '../router/uri';
import { Props as GuestFormProps } from '../../guests/components/GuestsForm/GuestsForm';
import HorizontalNavigation from '../../../template/components/HorizontalNavigation/HorizontalNavigation';
import HorizontalNavigationItem from '../../../template/components/HorizontalNavigation/HorizontalNavigationItem';
import LoadingSpinner from '../../../template/components/LoadingSpinner/LoadingSpinner';
import MasterEventHeader from '../components/MasterEventHeader/MasterEventHeader';
import MasterEventModel from '../models/MasterEvent';
import { URI_NOT_FOUND } from '../../errors/router/uri';
import { getLastUriSegment } from '../../../helper/router';
import routesMasterEvent from '../router/routesChildren';
import useCurrentUserCan from '../../users/services/useCurrentUserCan';
import useFetchMasterEvent from '../services/masterEvents/useFetchMasterEvent';
import useSetBreadcrumbItems from '../../../template/components/Breadcrumb/useSetBreadcrumbItems';
import useSetBreadcrumbVisible from '../../../template/components/Breadcrumb/useSetBreadcrumVisible';
import { URI_BOOKING } from '../../booking/router/uri';
import useGetGuestLabel from '../../guests/utils/useGetGuestLabel';
import PageContainer from '../../../template/components/PageContainer/PageContainer';
import useFetchSubEvents from '../services/subEvents/useFetchSubEvents';
import ErrorBoundary from '../../errors/components/ErrorBoundary';
import useBreakpointDown from '../../../template/hooks/useBreakpointDown';

const GuestForm = lazy(() => import('../../guests/components/GuestsForm/GuestsForm'));
const AddSubEventForm = lazy(() => import('../components/SettingsSubEventsForm/SettingsSubEventsForm'));

const defaultMasterEvent: MasterEventModel = {
  _id: '',
  _partition: 'MASTER',
  createdAt: new Date(),
  createdBy: '',
  endAt: new Date(),
  name: '',
  hasVirtualKeyboard: false,
  startAt: new Date(),
  users: [],
  ipads: [],
};

interface MasterEventContextInterface {
  masterEvent: MasterEventModel;
  setMasterEvent: (partial: Partial<MasterEventModel>) => void;
  onReload: () => void;
  guestFormOpen: boolean;
  setGuestFormOpen: (guestFormOpen: boolean) => void;
  guestFormProps: GuestFormProps;
  setGuestFormProps: React.Dispatch<React.SetStateAction<GuestFormProps>>;
}

export const MasterEventContext = createContext<MasterEventContextInterface>({
  masterEvent: defaultMasterEvent,
  setMasterEvent: () => {},
  onReload: () => {},
  guestFormOpen: false,
  setGuestFormOpen: () => {},
  guestFormProps: { onClose: () => {} },
  setGuestFormProps: () => {},
});

interface Props {
  masterEvent: MasterEventModel;
  onReload: () => void;
}

function MasterEventRender(props: Props) {
  const { masterEvent, onReload } = props;

  const [localMasterEvent, setLocalMasterEvent] = useState<MasterEventModel>(masterEvent);
  const [subEventFormOpen, setSubEventFormOpen] = useState(false);

  // The guest form is also part of MasterEventContext because it's controlled by multiple components:
  // the guests table, additional guests and also the booking
  const [guestFormOpen, setGuestFormOpen] = useState(false);
  const [guestFormProps, setGuestFormProps] = useState<GuestFormProps>({ onClose: () => {} });

  const setMasterEvent = (partial: Partial<MasterEventModel>) =>
    setLocalMasterEvent({ ...localMasterEvent, ...partial });

  const userCan = useCurrentUserCan();

  const { getGuestLabel } = useGetGuestLabel();

  const { fetchSubEvents, loading } = useFetchSubEvents();

  const location = useLocation();
  const isBookingPage = location.pathname.includes(`/${URI_BOOKING}/`);
  const isMobile = useBreakpointDown('md');

  const horizontalNavigationItems = routesMasterEvent(userCan, isMobile)
    .filter((route) => route.meta && route.meta.label)
    .map((route) => {
      let label = route.meta?.label || '';
      if (route.path === 'guests') {
        label = getGuestLabel({ capitalize: true, plural: true });
      }

      return {
        label,
        path: route.path === '/' || route.index ? '' : route.path,
      } as HorizontalNavigationItem;
    });

  /**
   * Get the active navigation menu item based on the current URI.
   */
  function getActiveNavItem(): number {
    const lastUri = getLastUriSegment();

    const activeNavItem = horizontalNavigationItems.findIndex((navItem) => {
      if (isMobile && navItem.path?.endsWith(`settings/${lastUri}`)) {
        return true;
      }
      return navItem.path === lastUri;
    });

    return activeNavItem !== -1 ? activeNavItem : 0;
  }

  const pageTitle = masterEvent.name;

  useSetBreadcrumbItems([{ label: 'AP Events', to: URI_MASTER_EVENTS }, { label: pageTitle }]);

  const loadEvents = async () => {
    const subEvents = await fetchSubEvents(undefined, [localMasterEvent._id]);
    if (subEvents.length === 0) {
      setSubEventFormOpen(true);
    }
  };

  useEffect(() => {
    if (!loading) {
      loadEvents();
    }
  }, []);

  const handleSubEventFormClose = (reload: boolean, event?: 'backdropClick' | 'escapeKeyDown') => {
    if ((event && event === 'backdropClick') || event === 'escapeKeyDown') return;
    setSubEventFormOpen(false);
    loadEvents();
  };

  return (
    <MasterEventContext.Provider
      value={{
        masterEvent: localMasterEvent,
        setMasterEvent,
        onReload,
        guestFormOpen,
        setGuestFormOpen,
        guestFormProps,
        setGuestFormProps,
      }}
    >
      <PageContainer pageTitle={pageTitle}>
        {isBookingPage ? (
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        ) : (
          <Fragment>
            <MasterEventHeader masterEvent={masterEvent} onReload={onReload} />
            <HorizontalNavigation items={horizontalNavigationItems} value={getActiveNavItem()} />
            <Box sx={{ display: 'flex', flex: 1, width: '100%' }}>
              <ErrorBoundary>
                <Outlet />
              </ErrorBoundary>
            </Box>
          </Fragment>
        )}
      </PageContainer>
      <Suspense fallback={<Fragment />}>
        {guestFormOpen && <GuestForm key={guestFormProps.guestId} {...guestFormProps} />}
        {subEventFormOpen && <AddSubEventForm onClose={handleSubEventFormClose} initialSubEvent={true} />}
      </Suspense>
    </MasterEventContext.Provider>
  );
}

function MasterEvent() {
  const { masterEventId } = useParams();
  const navigate = useNavigate();

  useSetBreadcrumbVisible(false, true);

  const { fetchMasterEvent, masterEvent, loading, error, isNotFound } = useFetchMasterEvent();

  const handleFetch = async () => {
    if (masterEventId) {
      await fetchMasterEvent(masterEventId);
    }
  };

  useEffect(() => {
    handleFetch();
  }, [masterEventId]);

  useEffect(() => {
    if ((!loading && error) || (!loading && isNotFound)) {
      navigate(`/${URI_NOT_FOUND}`);
    }
  }, [loading, error, isNotFound]);

  if (loading || !masterEvent) {
    return <LoadingSpinner fullPage />;
  }

  return <MasterEventRender masterEvent={masterEvent} onReload={handleFetch} />;
}

export default MasterEvent;
