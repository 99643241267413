import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  GridRowId,
  GridRowSelectionModel,
  GridRowsProp,
  GridSortModel,
  gridStringOrNumberComparator,
} from '@mui/x-data-grid-premium';
import Stack from '@mui/material/Stack';
import { GridGroupingColDefOverride } from '@mui/x-data-grid-pro/models/gridGroupingColDefOverride';
import useFetchCountries from '../../../countries/services/useFetchCountries';
import { APBanner, APDataGrid, APFlag } from '@ap/design-system';
import { APFlagCountryCode } from '@ap/design-system/dist/components/APFlag/types';
import { APGridColDef } from '@ap/design-system/dist/components/APDataGrid/types';
import DateFormat from '../../../../template/components/DateFormat/DateFormat';
import useFetchMasterEvents, {
  FindMasterEventsInput,
} from '../../../masterEvents/services/masterEvents/useFetchMasterEvents';
import useFetchSubEventsGlobal from '../../../masterEvents/services/subEvents/useFetchSubEventsGlobal';
import { EventReportingContext, ReportsFilters } from '../../pages/EventReporting';
import useBreakpointDown from '../../../../template/hooks/useBreakpointDown';

const defaultCurrentPage = 0; // Starts at 0
const defaultPageSize = 25;
const defaultSortModel: GridSortModel = [
  {
    field: 'startAt',
    sort: 'asc',
  },
];

function EventReportsSelectionTable() {
  const { filters, setFilters } = useContext(EventReportingContext);

  const [rows, setRows] = useState<GridRowsProp>([]);
  const [sortModel, setSortModel] = useState<GridSortModel>(defaultSortModel);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [currentPage, setCurrentPage] = useState(defaultCurrentPage);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);

  const { fetchMasterEvents, masterEvents, loading, error, pagination } = useFetchMasterEvents();
  const { fetchSubEvents, subEvents, loading: loadingSubEvents } = useFetchSubEventsGlobal();
  const { fetchCountries, countries } = useFetchCountries();

  const isMobile = useBreakpointDown('md');

  useEffect(() => {
    if (loading || !subEvents) {
      return;
    }

    const masterEventRows: {
      path: string[];
      id: string;
      country?: string;
      name?: string;
      startAt?: Date;
      endAt?: Date;
    }[] = [
      ...masterEvents.map((masterEvent) => ({
        path: [masterEvent._id],
        id: masterEvent._id,
        country: masterEvent.country?._id,
        name: masterEvent.name,
        startAt: masterEvent.startAt,
        endAt: masterEvent.endAt,
      })),
    ];

    for (const subEvent of subEvents) {
      const masterIndex = masterEventRows.findIndex((e) => subEvent._partition === e.id);
      masterEventRows.splice(masterIndex + 1, 0, {
        path: [subEvent._partition, subEvent._id],
        id: subEvent._id,
        name: subEvent.shortName ?? '',
      });
    }

    setRows([...masterEventRows]);
  }, [masterEvents, subEvents]);

  useEffect(() => {
    fetchCountries();
  }, []);

  const columns: APGridColDef[] = useMemo(
    () => [
      {
        field: 'country',
        headerName: 'Country',
        sortable: true,
        filterable: true,
        width: 110,
        align: 'center',
        type: 'singleSelect',
        valueOptions: countries
          .map((country) => ({
            value: country._id,
            label: country.name,
          }))
          .sort((c1, c2) => c1.label.localeCompare(c2.label)),
        renderCell: (params) => {
          const country = countries.find((country) => country._id === params.value);

          return country ? (
            <APFlag
              countryCode={country._id.toLowerCase() as APFlagCountryCode}
              countryName={country.name}
              width={20}
              height={20}
            />
          ) : (
            ''
          );
        },
        sortComparator: (value1, value2, param1, param2) => {
          const country1 = countries.find((country) => country._id === value1);
          const country2 = countries.find((country) => country._id === value2);

          return gridStringOrNumberComparator(country1?.name ?? value1, country2?.name ?? value2, param1, param2);
        },
      },
      {
        field: 'name',
        headerName: 'Name',
        ...(isMobile ? { width: 250 } : { flex: 2 }),
        hideable: false,
      },
      {
        field: 'startAt',
        headerName: 'Start date',
        ...(isMobile ? { width: 140 } : { flex: 1 }),
        renderCell: (params) => {
          if (!params.value) {
            return '';
          }
          return <DateFormat date={new Date(params.value)} fromNow={!isMobile} />;
        },
        filterable: false,
        hideable: false,
      },
      {
        field: 'endAt',
        headerName: 'End date',
        ...(isMobile ? { width: 140 } : { flex: 1 }),
        renderCell: (params) => {
          if (!params.value) {
            return '';
          }
          return <DateFormat date={new Date(params.value)} fromNow={!isMobile} />;
        },
        filterable: false,
        hideable: false,
      },
    ],
    [countries, isMobile],
  );

  const handleReload = useCallback(async () => {
    const paramSortModel = sortModel.length > 0 ? sortModel[0] : defaultSortModel[0];

    const dateRange = filters.find((e) => e.id === ReportsFilters.DATE_RANGE)?.value;
    const eventStatus = filters.find((e) => e.id === ReportsFilters.EVENT_STATUS)?.value ?? ['all'];

    const yearFromNow = new Date();
    yearFromNow.setFullYear(yearFromNow.getFullYear() + 1);

    const params: FindMasterEventsInput = {
      limit: pageSize,
      offset: currentPage === 0 ? 0 : currentPage * pageSize + 1,
      sortBy: paramSortModel.field,
      sortDirection: paramSortModel.sort as string,
      typeNotIn: ['BOUTIQUE-HOUSE'],
      countries: filters.find((e) => e.id === ReportsFilters.COUNTRIES)?.value ?? [],
      startDate: dateRange && dateRange[0] ? dateRange[0] : new Date(0, 0),
      endDate: dateRange && dateRange[1] ? dateRange[1] : yearFromNow,
    };

    if (eventStatus.includes('all') || (eventStatus.includes('past') && eventStatus.includes('archived'))) {
    } else if (eventStatus.includes('past')) {
      params.isPast = true;
      params.isArchived = false;
    } else if (eventStatus.includes('archived')) {
      params.isArchived = true;
    }

    const events = await fetchMasterEvents(params);

    await fetchSubEvents(events.masterEvents.map((p) => p._id));
  }, [pageSize, currentPage, sortModel, filters]);

  /**
   * Fetch the master events:
   * - on initial page load
   * - on pagination changes (rows per page option, current page)
   * - on sort changes
   * - on search
   */
  useEffect(() => {
    if (!loading || !loadingSubEvents) {
      handleReload();
    }
  }, [currentPage, pageSize, sortModel, filters]);

  const groupingColDef: GridGroupingColDefOverride = {
    headerName: '',
    hideDescendantCount: true,
    valueFormatter: () => '',
    width: 20,
  };

  if (!subEvents) {
    return null;
  }

  return (
    <Stack
      direction='column'
      spacing={4}
      sx={{
        display: 'flex',
        height: '100%',
        width: '100%',
        ...(isMobile && {
          // Add padding to the bottom to make space for the action buttons (generate reports)
          paddingBottom: 8,
        }),
      }}
    >
      {error && !loading && (
        <APBanner severity='danger' showIcon>
          An error occurred while fetching the master events
        </APBanner>
      )}
      <APDataGrid
        groupingColDef={groupingColDef}
        treeData
        getTreeDataPath={(row) => row.path}
        autoHeight
        rows={rows}
        columns={columns}
        sortingMode='server'
        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        checkboxSelection
        rowSelection
        localeText={{
          noRowsLabel: `No master events`,
          columnMenuSortAsc: 'Sort ascending',
          columnMenuSortDesc: 'Sort descending',
          columnMenuHideColumn: 'Hide column',
        }}
        loading={loading || loadingSubEvents}
        rowSelectionModel={selectionModel}
        keepNonExistentRowsSelected
        onRowSelectionModelChange={(newSelectionModel: GridRowId[]) => {
          // Find the selected or unselected id
          const newlySelected = newSelectionModel.filter((x) => !selectionModel.includes(x));
          const newlyUnselected = selectionModel.filter((x) => !newSelectionModel.includes(x));

          // Add all the child subevents to the selection
          if (newlySelected.length != 0) {
            const subEventsToAdd = subEvents
              .filter((e) => newlySelected.some((b) => b === e._partition))
              .map((e) => e._id);
            newSelectionModel = [...newSelectionModel, ...subEventsToAdd];
          }

          // Remove all the child subevents from the selection
          if (newlyUnselected.length != 0) {
            const subEventsToRemove = subEvents
              .filter((e) => newlyUnselected.some((b) => b === e._partition))
              .map((e) => e._id);
            newSelectionModel = [
              ...newSelectionModel.filter((e) => !subEventsToRemove.some((unselectedId) => unselectedId === e)),
            ];
          }

          // Check through the whole tree if masterEvent should be selected / unselected depending on the subevents
          for (const masterEvent of masterEvents) {
            const subEventsOfMasterEvent = subEvents.filter((e) => e._partition === masterEvent._id).map((e) => e._id);
            if (subEventsOfMasterEvent.length !== 0) {
              if (subEventsOfMasterEvent.every((a) => newSelectionModel.includes(a))) {
                const masterEventId = masterEvent._id;
                if (!newSelectionModel.some((e) => e === masterEventId)) {
                  newSelectionModel.push(masterEventId);
                }
              } else if (newSelectionModel.some((e) => e === masterEvent._id)) {
                newSelectionModel = newSelectionModel.filter((e) => e !== masterEvent._id);
              }
            }
          }

          // Remove selection for current page
          const masterEventsFilter = filters.find((e) => e.id === ReportsFilters.MASTER_EVENTS);
          if (masterEventsFilter) {
            for (const masterEventId of masterEventsFilter.value) {
              if (masterEvents.some((e) => e._id === masterEventId)) {
                masterEventsFilter.value = masterEventsFilter.value.filter((e: string) => e !== masterEventId);
              }
            }
          }
          const subEventsFilter = filters.find((e) => e.id === ReportsFilters.SUB_EVENTS);
          if (subEventsFilter) {
            for (const subEventId of subEventsFilter.value) {
              if (subEvents.some((e) => e._id === subEventId)) {
                subEventsFilter.value = subEventsFilter.value.filter((e: string) => e !== subEventId);
              }
            }
          }

          // Push new selection for current page
          for (const id of newSelectionModel) {
            // Push master event to filters
            if (masterEvents.find((e) => e._id === id)) {
              masterEventsFilter?.value.push(id as string);
            }
            // Push sub event to filters if master event is not selected
            const subEvent = subEvents.find((e) => e._id === id);
            if (subEvent && !newSelectionModel.some((e) => e === subEvent._partition)) {
              subEventsFilter?.value.push(id as string);
              if (!masterEventsFilter?.value.some((e: string) => e === subEvent._partition)) {
                masterEventsFilter?.value.push(subEvent._partition as string);
              }
            }
          }

          setFilters([...filters]);
          setSelectionModel(newSelectionModel);
        }}
        pagination
        paginationModel={{ page: currentPage, pageSize }}
        pageSizeOptions={[10, 25, 50, 100]}
        paginationMode='server'
        simplePagination={isMobile}
        onPaginationModelChange={(model) => {
          setPageSize(model.pageSize);
          setCurrentPage(model.page);
        }}
        rowCount={pagination.totalItems}
        disableColumnMenu={isMobile}
      />
    </Stack>
  );
}

export default EventReportsSelectionTable;
