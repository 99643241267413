import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { APButton, APFlag } from '@ap/design-system';
import { APFlagCountryCode } from '@ap/design-system/dist/components/APFlag/types';
import MasterEventModel from '../../models/MasterEvent';
import { StyledCardMedia } from './styles';
import { parseHtml } from '../../../../helper/html';
import useBreakpointDown from '../../../../template/hooks/useBreakpointDown';
import { addDays, isAfter } from 'date-fns';
import useGetCurrentUser from '../../../users/services/useGetCurrentUser';
import { canArchiveEvent } from '../../../../helper/canArchiveEvent';
import { Fragment, Suspense, lazy, useMemo, useState } from 'react';

const ArchiveEventDialog = lazy(() => import('../ArchiveEventDialog/ArchiveEventDialog'));

function brTitle(title: string) {
  return parseHtml(title.replace('Audemars Piguet', `Audemars Piguet<br />`).replace('AP House', `AP House<br />`));
}

interface Props {
  masterEvent: MasterEventModel;
  onReload: () => void;
}

function MasterEventHeader(props: Props) {
  const currentUser = useGetCurrentUser();
  const { masterEvent, onReload } = props;
  const { name, coverImage, country, endAt, isArchived } = masterEvent;
  const isMobile = useBreakpointDown('md');
  const [dialogArchiveOpen, setDialogArchiveOpen] = useState(false);

  const isEventEnded = isAfter(new Date(), addDays(new Date(endAt), 1));
  const canArchive = useMemo(() => canArchiveEvent(currentUser, masterEvent), [currentUser, masterEvent]);

  const handleDialogArchiveClose = (archived: boolean) => {
    setDialogArchiveOpen(false);
    if (archived && onReload) {
      onReload();
    }
  };

  return (
    <Fragment>
      <Stack
        spacing={4}
        direction={isMobile ? 'column' : 'row'}
        justifyContent='space-between'
        alignItems={isMobile ? 'flex-start' : 'center'}
        sx={{ width: '100%' }}
      >
        <Stack
          spacing={4}
          direction={isMobile ? 'column' : 'row'}
          sx={{
            ...(isMobile && { width: '100%' }),
          }}
        >
          {coverImage && <StyledCardMedia image={coverImage.urlFull} />}
          <Stack spacing={2}>
            <Typography variant='displayTitle'>{brTitle(name)}</Typography>
            {isEventEnded && (isArchived === null || !isArchived) && canArchive && (
              <APButton onClick={() => setDialogArchiveOpen(true)} aria-label='archive' sx={{ width: 'fit-content' }}>
                + ARCHIVE
              </APButton>
            )}
          </Stack>
        </Stack>
        {country && !isMobile && (
          <APFlag
            countryCode={country._id.toLowerCase() as APFlagCountryCode}
            countryName={country.name}
            showTooltip
            height='40px'
            width='40px'
          />
        )}
      </Stack>
      <Suspense fallback={<Fragment />}>
        {dialogArchiveOpen && <ArchiveEventDialog onClose={handleDialogArchiveClose} masterEventId={masterEvent._id} />}
      </Suspense>
    </Fragment>
  );
}

export default MasterEventHeader;
