import MasterEvent, { MasterEventUserType } from '../features/masterEvents/models/MasterEvent';
import CustomUserData from '../features/users/models/CustomUserData';
import { UserRole } from '../features/users/enums/UserRole';

export const canArchiveEvent = (user: CustomUserData, event: MasterEvent): boolean => {
  if (user.roles.includes(UserRole.ADMIN)) {
    return true;
  }

  return event.users.some(
    (eventUser) => eventUser.externalUserId === user.externalUserId && eventUser.type === MasterEventUserType.ADMIN,
  );
};
